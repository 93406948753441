import { pushGA4CustomEvent } from "@ocm/services/analytics4Tracking";
import pushDataLayer from "@ocm/services/dataLayer";
import { isClassicSite } from "@ocm/services/ocm-object";

export const tr_searchEvent = (payload: Record<string, string>) => {
	pushGA4CustomEvent("search", payload);
};

export const tr_searchClick = (target: "Icon" | "Field") => {
	const prefix = isClassicSite ? "C" : "S";
	pushDataLayer({
		event: "GAevent",
		event_category: `${prefix}_1stlevel`,
		event_action: `${prefix}_${target} Desktop Search`,
	});
};
