/**
 * @param {Object} obj
 * @returns {string[]}
 */
const _getAllValues = (obj) =>
	Object.values(obj).reduce((acc, item) => {
		if (typeof item === "string") acc.push(item);
		else if (typeof item === "object" && item !== null) acc.push(..._getAllValues(item));
		return acc;
	}, []);

export const skipLinks = {
	main: "#main-content",
	search: {
		leftDrawerButton: "#open-search-left-drawer",
		rightDrawerButton: "#open-search-right-drawer",
		input: "#header-search",
	},
};

export const skipLinkHashes = _getAllValues(skipLinks);
