import { computed, ref, nextTick } from "vue";
import fetchJsonp from "fetch-jsonp";
import { useDebounceFn } from "@vueuse/core";
import { tr_searchEvent } from "@/services/dataLayer";
import { contextPath, isClassicSite } from "@ocm/services/ocm-object";
import useDepartment from "./use-department";
import useMostSuggestedRequest from "./use-most-suggested-request";
import useFindings, { findingsSize } from "./use-findings";
import { getOcmRequestUrl } from "@/services/ocm-request-settings";
import { getEpoqRequestParams } from "@/services/epoq-request-settings";
import {
	epoqSearchURL,
	epoqSearchFallbackURL,
	epoqSearchFallbackTimeout,
	epoqRequestTimeout,
} from "@ocm/services/services.epoq";
import { EpoqResult } from "@/types/epoq.type";

type OcmResult = {
	typeAheadItems: TypeAheadItem[];
};
type TypeAheadItem = {
	entries: [
		{
			content: string;
			link: string;
		}
	];
	headline: string;
};

export const typeAheadItems = ref<TypeAheadItem[]>([]);
export const hasTypeAheadItems = computed(() => typeAheadItems.value.length > 0);

export const focusedTypeAheadId = ref<string | undefined>();
export const focusedTypeAheadIndex = ref<number>(-1);

const requestFinished = ref(true);
const isLoading = ref(false);
const _setLoading = () => {
	if (!requestFinished.value) isLoading.value = true;
};
const _debounceLoading = useDebounceFn(_setLoading, 150);
const _setRequestFinished = () => {
	requestFinished.value = true;
	isLoading.value = false;
};

const userInput = ref("");
const userInputMaxlength = 150;
// android fix for not updated v-model value on input TUB-16479
const setUserInput = (newInput: string) => {
	userInput.value = newInput;
};
let searchQuery = "";
const isSearchMostSuggested = computed(() => userInput.value.length < 3);
const isSearchVisible = ref(false);

const _ocmRequest = async () => {
	const requestUrl = getOcmRequestUrl(searchQuery);
	try {
		const response = await fetch(requestUrl);
		if (!response.ok) throw new Error("OCM TypeAhead request failed");

		const data: OcmResult = await response.json();
		typeAheadItems.value = data.typeAheadItems.filter((item) => item.entries.length > 0);
	} catch (err: unknown) {
		console.error(err);
	}
};

const { setFindings } = useFindings();
const _epoqRequest = async (requestUrl: string) => {
	const requestParams = getEpoqRequestParams(searchQuery);
	const jsonpUrl = new URL(`${requestUrl}?${new URLSearchParams(requestParams)}`).toString();
	requestFinished.value = false;
	_debounceLoading();

	try {
		const response = await fetchJsonp(jsonpUrl);
		if (!response.ok) throw new Error("Epoq Search request failed");

		const { result }: { result: EpoqResult } = await response.json();
		setFindings(result);
	} catch (err: unknown) {
		console.error(err);
	} finally {
		_setRequestFinished();
	}
};

let useFallback = false;
const _epoqRequestWithFallback = async () => {
	try {
		await Promise.race([_epoqRequest(useFallback ? epoqSearchFallbackURL : epoqSearchURL), epoqRequestTimeout()]);
	} catch (err: unknown) {
		console.error(err);
		if (useFallback) return;

		useFallback = true;
		// retry with fallback URL
		_epoqRequestWithFallback();
		// reset to default URL after 5 minutes
		setTimeout(() => {
			useFallback = false;
		}, epoqSearchFallbackTimeout);
	}
};

const setUpRequest = () => {
	isClassicSite ? _ocmRequest() : _epoqRequestWithFallback();
};

const _debounceRequest: () => void = useDebounceFn(setUpRequest, 200);
const inputEl = ref<HTMLInputElement | null>(null);

const triggerTypeAhead = () => {
	if (!isSearchMostSuggested.value) {
		searchQuery = userInput.value;
		_debounceRequest();
	}
};

const focusSearch = () => {
	inputEl?.value?.focus();
};

const setInputElementRef = (ref: HTMLInputElement | null) => {
	inputEl.value = ref;
};

const resetSearch = () => {
	userInput.value = "";
	inputEl?.value?.blur();
	closeSearch();
};

const openSearch = () => {
	if (isSearchVisible.value) {
		focusSearch();
		return;
	}

	useMostSuggestedRequest().loadSuggestions();
	isSearchVisible.value = true;
	nextTick(() => {
		focusSearch();
	});
	triggerTypeAhead();
};

const closeSearch = () => {
	if (!isSearchVisible.value) return;
	isSearchVisible.value = false;
	inputEl?.value?.blur();
};

const deleteSearch = () => {
	userInput.value = "";
	focusSearch();
	triggerTypeAhead();
};

const _navigateToClassicSearchResult = (searchEncoded: string) => {
	const link = typeAheadItems.value?.[0]?.entries?.[0]?.link;
	if (userInput.value.length > 2 && link?.includes("labelId")) {
		window.location.assign(link);
	} else {
		window.location.assign(`${contextPath}/content-search/?text=${searchEncoded}`);
	}
};

const { departmentCapitalized, department } = useDepartment();
const _navigateToShopSearchResult = (searchEncoded: string) => {
	const url = `${contextPath}/suche/?s=${searchEncoded}&department=${department.value}#/Kategorie/${departmentCapitalized.value}`;

	if (window.ocmOrchestrator) {
		window.ocmOrchestrator.spaNavigateUrl(url);
		resetSearch();
	} else {
		window.location.assign(url);
	}
};

enum triggerType {
	click = "textsearch",
	keyboard = "textsearch",
	voice = "voicesearch",
}
const triggerSearch = (trigger: "voice" | "click" | "keyboard" = "keyboard") => {
	const navigated = navigateToSelectedTypeAheadItem();
	if (navigated || userInput.value.length < 1) return;

	tr_searchEvent({ searchterm: userInput.value, type: triggerType[trigger] });

	const searchEncoded = encodeURIComponent(userInput.value.substring(0, userInputMaxlength).trim());
	isClassicSite ? _navigateToClassicSearchResult(searchEncoded) : _navigateToShopSearchResult(searchEncoded);

	if (window.EpoqNS) {
		console.log("%c%s", "color:#fff;background-color:#35495e;", "search: EpoqNS.epoqForeignInit");
		const stateUrl = window.location.href;
		window.EpoqNS.epoqForeignInit({ stateUrl });
	}
};

function navigateToSelectedTypeAheadItem() {
	if (focusedTypeAheadIndex.value > -1 && focusedTypeAheadId.value) {
		const linkDestination = document.getElementById(focusedTypeAheadId.value)?.getAttribute("href");
		if (linkDestination) {
			window.location.assign(linkDestination);
			return true;
		}
	}
	return false;
}

export const onInput = (target: HTMLInputElement) => {
	setUserInput(target.value);
	focusedTypeAheadIndex.value = -1;
	focusedTypeAheadId.value = undefined;
	triggerTypeAhead();
};

export const onKeyDown = (event: KeyboardEvent) => {
	switch (event.key) {
		case "ArrowUp":
			handleArrowUpAndDown(-1);
			event.preventDefault(); // prevent scrolling when focus is on submit or microphone button and from moving cursor
			break;
		case "ArrowDown":
			handleArrowUpAndDown(1);
			event.preventDefault();
			break;
	}
};

const { mostSuggestedItems } = useMostSuggestedRequest();
const nrOfDisplayedTypeAheadItems = computed(() => {
	switch (true) {
		case isSearchMostSuggested.value:
			return mostSuggestedItems.value.mostSearchItems.length;
		case isClassicSite:
			return typeAheadItems.value.reduce((acc, curr) => acc + curr.entries.length, 0);
		default:
			return findingsSize.value;
	}
});

const handleArrowUpAndDown = (movementIndex: number) => {
	const totalItems = nrOfDisplayedTypeAheadItems.value;
	focusedTypeAheadIndex.value = (focusedTypeAheadIndex.value + movementIndex + totalItems) % totalItems;
};

export default function useSearch() {
	return {
		isLoading,
		requestFinished,
		setUpRequest,
		userInput,
		userInputMaxlength,
		setInputElementRef,
		isSearchVisible,
		isSearchMostSuggested,
		openSearch,
		closeSearch,
		focusSearch,
		deleteSearch,
		triggerSearch,
		resetSearch,
		onKeyDown,
		onInput,
		nrOfDisplayedTypeAheadItems,
	};
}
