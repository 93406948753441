export const country = window.ocm?.config?.country || "de";
export const language = window.ocm?.config?.language || "de";
export const contextPath = window.ocm?.config?.encodedContextPath ?? `/${country}-de/shop`;
export const isClassicSite = window.ocm?.isClassicSite ?? false;
export const shopContextPath = isClassicSite ? `/${country}-de/shop` : contextPath;

// Epoq
export const isEpoqRecosCartEnabled = window.ocm?.config?.isEpoqRecosCartEnabled;
export const epoqRecosPlpChunkSize = window.ocm?.config?.epoqRecosPlpChunkSize;
export const isEpoqRecosPdpEnabled = window.ocm?.config?.isEpoqRecosPdpEnabled;

// Contact Center
export const captchaPublickey = window.ocm?.captchaOptions?.publickeyContactcenter || "";
export const captchaActive = window.ocm?.captchaOptions?.displayContactcenter;

export const department = window.ocm?.config?.department;

// My Account
export const memberGetsMemberClassicEnabled = window.ocm?.config?.memberGetsMemberClassicEnabled ?? false;
export const memberGetsMemberShopEnabled = window.ocm?.config?.memberGetsMemberShopEnabled ?? false;

// TUB-21694 Versand- und Retoureninformation A/B-Test
export const isPdpShippingInformationEnabled = window.ocm?.config?.isPdpShippingInformationEnabled ?? false;
