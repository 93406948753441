import { ref, computed } from "vue";
import { department as _department } from "@ocm/services/ocm-object";
import type { Department } from "@ocm/global-types";

const department = ref(_department);
const departmentCapitalized = computed(
	() => (department.value.charAt(0).toUpperCase() + department.value.slice(1)) as Capitalize<Department>
);

export default function useDepartment() {
	return {
		department,
		departmentCapitalized,
	};
}
