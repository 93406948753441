import { Ref, ref, computed } from "vue";
import { Findings } from "@/types/findings.type";
import { EpoqResult, EpoqFinding } from "@/types/epoq.type";

type UseFindings = {
	findings: Readonly<Ref<Findings>>;
	hasFindings: Readonly<Ref<boolean>>;
	setFindings: (result: EpoqResult) => void;
};

const _emptyFindings = (): Findings => ({
	brands: [],
	categories: [],
	others: [],
});
const findings: Ref<Findings> = ref(_emptyFindings());
const hasFindings = computed(
	(): boolean =>
		findings.value.brands.length > 0 || findings.value.categories.length > 0 || findings.value.others.length > 0
);
export const findingsSize = computed(
	() => findings.value.brands.length + findings.value.categories.length + findings.value.others.length
);

const _populateFindings = (result: EpoqResult): Findings => {
	const findings = _emptyFindings();
	const _epoqFindings = result.findings?.finding;
	if (_epoqFindings) {
		(Array.isArray(_epoqFindings) ? _epoqFindings : [_epoqFindings]).forEach((item: EpoqFinding) => {
			switch (item.suggestion["@field"]) {
				case "g:product_type": {
					findings.categories.push(item.suggestion.$);
					break;
				}
				case "g:brand": {
					findings.brands.push(item.suggestion.$);
					break;
				}
				default: {
					findings.others.push(item.suggestion.$);
				}
			}
		});
	}

	return findings;
};
const setFindings = (result: EpoqResult): void => {
	findings.value = _populateFindings(result);
};

export default function useFindings(): UseFindings {
	return {
		findings,
		hasFindings,
		setFindings,
	};
}
