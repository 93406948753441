import { ref, computed } from "vue";
import useDepartment from "./use-department";
import { contextPath } from "@ocm/services/ocm-object";

type MostSuggestedItemsResult = {
	headline: string;
	mostSearchItems: Array<{
		url: string;
		linkName: string;
		sameWindow: boolean;
	}>;
};

const mostSuggestedItems = ref<MostSuggestedItemsResult>({
	headline: "",
	mostSearchItems: [],
});
const hasMostSuggestedItems = computed(() => mostSuggestedItems.value.mostSearchItems.length > 0);

const { department } = useDepartment();
const loadSuggestions = async () => {
	const url =
		department.value !== ""
			? `${contextPath}/rest/search/config/?department=${department.value}`
			: `${contextPath}/rest/search/config/`;

	try {
		const response = await fetch(url);
		if (!response.ok) throw new Error("Most Suggested request failed");

		const data: MostSuggestedItemsResult = await response.json();
		mostSuggestedItems.value = data;
	} catch (err: unknown) {
		console.error(err);
	}
};

export default function useMostSuggestedRequest() {
	return {
		mostSuggestedItems,
		hasMostSuggestedItems,
		loadSuggestions,
	};
}
